import { atom, useAtomValue } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

import { t } from '../service/i18n';

export enum LangCode {
  FR = 'fr',
  EN = 'en',
  ES = 'es',
  PT = 'pt-pt',
  IT = 'it',
  DE = 'de',
  ZH = 'zh-hans',
  HI = 'hi',
  RU = 'ru',
  AR = 'ar',
  JP = 'ja',
}

export const currentLangAtom = atomWithStorage<LangCode>('lang', LangCode.FR);

export const langAtom = atom(
  (get) => get(currentLangAtom),
  (_, set, newLang: LangCode) => {
    document.documentElement.setAttribute('lang', newLang);
    set(currentLangAtom, newLang);
  },
);

export const langSelectedAtom = atomWithStorage<boolean>('lang_selected', false);

export const useTranslate = () => {
  const code = useAtomValue(langAtom);
  return (key: string) => t(code, key);
};
