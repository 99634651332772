import { t } from '../../service/i18n';
import { LangCode } from '../../state/lang';

import styles from './styles.module.scss';

interface IProps {
  languages: Record<LangCode, [string, string]>;
  setLang: (lang: LangCode) => void;
  currentLang: LangCode;
}

function LangSelector({ languages, setLang, currentLang }: IProps) {
  return (
    <div className={styles.lang_parent}>
      <p>{t(currentLang, 'choose_the_lang')}</p>

      <ul className={styles.lang_selector}>
        {Object.keys(languages).map((code) => {
          const langCode = code as LangCode;
          return (
            <li
              key={code}
              className={`${styles.selector} ${code === currentLang ? styles.selected : ''} 
              ${langCode === LangCode.AR ? styles.dropArabic : ''}`}
              onClick={() => setLang(langCode)}>
              <span>{languages[langCode][0]}</span>
              {languages[langCode][1]}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default LangSelector;
