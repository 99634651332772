import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useEffect } from 'react';
import { sendPageEvent } from '../service/event';

export type ParamsBag = { [key: string]: unknown };

export type Screen = {
  name: string;
  params: ParamsBag;
};

/**
 * SCREEN NAVIGATION
 */
// export const screenAtom = atomWithStorage<Screen>('screen', {name: 'welcome', params: {}})
export const screenAtom = atom<Screen>({ name: 'home', params: {} });

export const useGoToScreen = () => {
  const [screen, setScreen] = useAtom(screenAtom);
  console.log(screen);

  return (name: string, params: ParamsBag = {}) => {
    window.history.pushState({ name, params }, '', `#${name}`); //${JSON.stringify(params)}
    console.log('push state', { name, params });
    setScreen({ name, params });
  };
};

export const useGoBack = () => {
  return () => {
    window.history.back();
  };
};

export const usePopStateListener = () => {
  const setScreen = useSetAtom(screenAtom);

  const onPopState = (e: PopStateEvent) => {
    console.log('pop state', e.state);
    if (e.state) {
      setScreen({
        name: e.state.name,
        params: { ...e.state.params, back: true },
      });
    } else {
      setScreen({
        name: 'home',
        params: {},
      });
      console.log('try to pop an empty state');
    }
  };

  return useEffect(() => {
    window.addEventListener('popstate', onPopState);
    return () => {
      window.removeEventListener('popstate', onPopState);
    };
  });
};

if (window.history.scrollRestoration) {
  window.history.scrollRestoration = 'manual';
}

/**
 * NAV BURGER STATE
 */
export const burgerOpenAtom = atom(false);

/**
 * ws refreshed
 */
export const wsReloadedAtom = atomWithStorage<boolean>('ws_relaod', false);

export const eventLoggerEnabledAtom = atomWithStorage<boolean>('event_logger', false);
export const useLogEvent = () => {
  const eventLoggerEnabled = useAtomValue(eventLoggerEnabledAtom);
  return eventLoggerEnabled ? sendPageEvent : () => {};
};

const url = new URLSearchParams(window.location.search);
export const isDev = url.get('debug') === 'on' || !!parseInt(process.env.REACT_APP_DEBUG || '');
