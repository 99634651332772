import { useAtom } from 'jotai';
import { useMemo } from 'react';

import { screenAtom } from '../../state/app';
import logo from '../../imgs/bateaux-parisien.png';

import styles from './styles.module.scss';
import LangDropdown from '../LangDropdown';
import BackButton from '../BackButton';
import Burger from '../Burger';

interface IProps {
  ready: boolean;
}

function AppHeader({ ready }: IProps) {
  const [screen] = useAtom(screenAtom);
  const haveContent = useMemo(() => screen.name !== 'welcome' && screen.name !== 'loading' && ready, [screen.name, ready]);

  return (
    <header className={styles.header}>
      <span className={styles.wave}></span>
      <img src={logo} alt={'Bateaux Parisien logo'} />
      {haveContent && (
        <div className={styles.content + (screen.name === 'home' ? ' home' : '')}>
          {screen.name === 'home' ? (
            <LangDropdown />
          ) : (
            <>
              <Burger />
              <BackButton />
            </>
          )}
        </div>
      )}
    </header>
  );
}

export default AppHeader;
