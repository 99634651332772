export const formatDate = (d: Date) => {
  return [
    d.getFullYear(),
    (d.getMonth() + 1).toString().padStart(2, '0'),
    d.getDate().toString().padStart(2, '0'),
    d.getHours(),
    d.getMinutes(),
    d.getSeconds(),
  ].join('');
};
