import React from 'react';

import styles from './styles.module.scss';

interface IProps {
  children: React.ReactNode;
  callback?: () => void;
  href?: string;
  disabled?: boolean;
  props?: object;
}

function Button({ children, callback, href, disabled, props }: IProps) {
  if (href) {
    props = props || {};
    return (
      <a href={href} onClick={callback} className={styles.Button} {...props}>
        {children}
      </a>
    );
  }

  return (
    <button onClick={callback} className={styles.Button} disabled={disabled}>
      {children}
    </button>
  );
}

export default Button;
