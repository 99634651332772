import { MouseEventHandler } from 'react';
import styles from './styles.module.scss';

type CloseButtonProps = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  className?: string;
};

export const CloseButton = ({ onClick, className }: CloseButtonProps) => (
  <button onClick={onClick} className={`${styles.closeBtn} ${className || ''}`}>
    <svg xmlns={'http://www.w3.org/2000/svg'} width={'51.726'} height={'51.726'} viewBox={'0 0 51.726 51.726'}>
      <g id={'X'} transform={'translate(-948.986 -453.134)'}>
        <path
          id={'Tracé_66929'}
          data-name={'Tracé 66929'}
          d={'M59.49,49.433v60.943'}
          transform={'translate(989.357 380.356) rotate(45)'}
          fill={'none'}
          stroke={'#fff'}
          strokeLinecap={'round'}
          strokeWidth={'6'}
        />
        <path
          id={'Tracé_66931'}
          data-name={'Tracé 66931'}
          d={'M59.49,49.433v60.943'}
          transform={'translate(876.208 464.488) rotate(-45)'}
          fill={'none'}
          stroke={'#fff'}
          strokeLinecap={'round'}
          strokeWidth={'6'}
        />
      </g>
    </svg>
  </button>
);
