// Type discriminators helper functions

export function canMatchType<T>(obj: unknown, toSatisfy: string[]): obj is T {
  if (obj && typeof obj === 'object') {
    const toVerify = Object.keys(obj);

    return toSatisfy.every((key) => toVerify.includes(key));
  }

  return false;
}
