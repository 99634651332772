import { useRef, useEffect, useState, useCallback } from 'react';
import { SupportedModels, createDetector, movenet, PoseDetector } from '@tensorflow-models/pose-detection';
import * as tensorflow from '@tensorflow/tfjs-core';
import '@tensorflow/tfjs-backend-webgl';
import { Trans } from '../../component/trans';
import { draw } from '../../service/photo-ar-pack';

import styles from './styles.module.scss';
import hat1 from '../../imgs/pack-ar/1-hat.png';
import hat2 from '../../imgs/pack-ar/2-hat.png';
import Loader from '../../component/Loader';
import Button from '../../component/Button';
import { useTranslate } from '../../state/lang';
import { CloseButton } from '../../component/CloseButton';
import { formatDate } from '../../service/date';
import { isDev } from '../../state/app';
import Camera from '../../service/camera';

const hats = [hat1, hat2];

enum State {
  NOT_READY,
  CAPTURED,
  SAVED,
  LIVE,
}

/**
 * init tensor flow
 */
if (isDev && false) tensorflow.enableDebugMode();
else tensorflow.enableProdMode();
export const tfjsPreloadPromise = async () => {
  console.log('tf preload');
  await tensorflow.setBackend('webgl');
  await tensorflow.ready();
  console.log('tf ready');
  const tfjsDetectorPromise = createDetector(SupportedModels.MoveNet, {
    modelType: movenet.modelType.MULTIPOSE_LIGHTNING,
    modelUrl: '/tfjs-model/model.json',
  });
  tfjsDetectorPromise.catch((e) => console.error('error create detector', e));
  tfjsDetectorPromise.then((tf) => {
    console.log('tf inited');
    tf.dispose();
  });
  return tfjsDetectorPromise;
};
/**
 *
 */

export const Photo = () => {
  const [error] = useState<string | null>();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoWidth = 1080; //window.innerWidth * 2;
  const videoHeight = 1080; //window.innerHeight * 2;
  const rafRef = useRef<number>();
  const [canSwitch, setCanSwitch] = useState(true);
  const tfjsDetectorRef = useRef<PoseDetector>();
  // pack state froce render
  const [pack, setPackState] = useState<number>();
  const packRef = useRef<number>();
  const [showCam, setShowCam] = useState(false);
  const [state, setState] = useState<State>(State.NOT_READY);
  const [capturedAt, setCapturedAt] = useState<Date>();
  const t = useTranslate();
  const [mirror, setMirror] = useState<boolean>();
  const [tfjsReady, setTfjsReady] = useState(false);
  const [camReady, setCamReady] = useState(false);

  const camera = Camera.getInstance();

  const setPack = (i: number) => {
    setPackState(i);
    packRef.current = i;
  };

  const runFaceDetect = useCallback(async () => {

    // avoid  parallel call
    if (rafRef.current) window.cancelAnimationFrame(rafRef.current);

    if (videoRef.current && canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d');
      if (!ctx) return;
      if (!videoRef.current.videoWidth || !videoRef.current.videoHeight) return;

      canvasRef.current.width = videoRef.current.videoWidth;
      canvasRef.current.height = videoRef.current.videoHeight;
      const w = (videoRef.current.videoWidth * canvasRef.current.clientHeight) / videoRef.current.videoHeight;
      canvasRef.current.style.width = `${w}px`;
      ctx.resetTransform();
      ctx.drawImage(
        videoRef.current,
        0,
        0,
        videoRef.current.videoWidth,
        videoRef.current.videoHeight,
        0,
        0,
        videoRef.current.videoWidth,
        videoRef.current.videoHeight,
      );
      if (tfjsDetectorRef.current && packRef.current !== undefined) {
        const detector = tfjsDetectorRef.current;
        tfjsDetectorRef.current = undefined;
        try {
          const detections = await detector.estimatePoses(videoRef.current, {
            flipHorizontal: false,
            maxPoses: 3,
          });
          if (ctx && detections && packRef.current !== undefined) {
            detections.forEach((p) => draw(p, ctx, packRef.current as number));
          }
        } catch (e) {
          console.error(e);
        }
        if (!tfjsDetectorRef.current) tfjsDetectorRef.current = detector;
      }
    }
    rafRef.current = window.requestAnimationFrame(runFaceDetect);
  }, []);

  const stopDetection = useCallback(() => {
    console.log('stopDetection');
    if (rafRef.current) {
      window.cancelAnimationFrame(rafRef.current);
      rafRef.current = undefined;
    }
    if (tfjsDetectorRef.current) {
      try {
        tfjsDetectorRef.current.dispose();
      } catch (e) {
        console.error(e);
      }
      tfjsDetectorRef.current = undefined;
    }
    setState(State.NOT_READY);
    camera.stop();
  }, [camera]);

  const startDetection = useCallback(() => {
    console.log('startDetection');

    if (!tfjsDetectorRef.current) {
      const detectorPromise = createDetector(SupportedModels.MoveNet, {
        modelType: movenet.modelType.MULTIPOSE_LIGHTNING,
        modelUrl: '/tfjs-model/model.json',
      });

      detectorPromise.then(async (detector) => {
        if (!detector) return;
        tfjsDetectorRef.current = detector;
        // init models
        if (videoRef.current) {
          try {
            console.log('estimate');
            try {
              await detector.estimatePoses(videoRef.current);
            } catch(e) {
              console.error(e)
            }
            setTfjsReady(true)
            if(camReady) setState(State.LIVE)
          } catch (e) {
            console.log('erreur init detector', e);
          }
        }
      });
    }
    runFaceDetect();
  }, [camReady, runFaceDetect]);


  // start listen for stream ready
  useEffect(() => {
    console.log('useEffect 1');
    const onStreamChange = (stream: MediaStream) => {
      console.log('onStreamChange', videoRef.current?.srcObject, camera);
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }
      setMirror(camera.isMirror());
      // if (camera.devices && camera.devices.length > 1) setCanSwitch(true);
      setCamReady(true)
      if(tfjsReady) setState(State.LIVE)
      runFaceDetect();
    };
    camera.subscribe(onStreamChange);

    return () => {
      camera.unsubscribe(onStreamChange);
    };
  }, [setMirror, setCanSwitch, camera, runFaceDetect, setState, tfjsReady]);

  // 
  useEffect(() => {
    console.log('useEffect 2');
    if (!videoRef.current) {
      console.log('no video ref');
      return;
    }

    const video = videoRef.current;

    const onCanPlay = () => {
      if (canvasRef.current && videoRef.current) {
        const height = canvasRef.current.clientHeight;
        const width = (videoRef.current.videoWidth / videoRef.current.videoHeight) * height;
        console.log('canplay', width, height);
        videoRef.current.setAttribute('width', '' + width);
        videoRef.current.setAttribute('height', '' + height);
        canvasRef.current.setAttribute('width', '' + width);
        canvasRef.current.setAttribute('height', '' + height);
        startDetection();
      }
    };

    const onLoadedMetadata = () => {
      if (!canvasRef.current) return;

      const height = canvasRef.current.clientHeight;
      const width = (video.videoWidth / video.videoHeight) * height;
      video.setAttribute('width', '' + width);
      video.setAttribute('height', '' + height);
      canvasRef.current.setAttribute('width', '' + width);
      canvasRef.current.setAttribute('height', '' + height);
    };

    video.addEventListener('canplay', onCanPlay, false);
    video.addEventListener('loadedmetadata', onLoadedMetadata);

    return () => {
      video.removeEventListener('canplay', onCanPlay);
      video.removeEventListener('loadedmetadata', onLoadedMetadata);
    };
  }, [startDetection, videoHeight, videoWidth]);


  useEffect(() => {
    console.log('useEffect 2');

    if (!videoRef.current) {
      console.log('no video ref');
      return;
    }

    const video = videoRef.current;

    try {
      video.play();
    } catch (e) {
      // reload component can stop play
      console.error('error on play video', e);
    }

    return () => {
      // console.log('videoStream:', videoStream)
      //if videoStreamAlreadyDefined
      if (videoRef.current?.srcObject) {
        // disable cam
        videoRef.current.srcObject = null;
        console.log('unmounted');
        stopDetection();
      } else console.log('mounting');
    };
  }, [stopDetection]);

  const pauseDetection = () => {
    console.log('pauseDetection');

    if (rafRef.current) {
      window.cancelAnimationFrame(rafRef.current);
      rafRef.current = undefined;
    }
  };

  const switchDevice = () => {
    console.log('switchDevice');
    pauseDetection();
    setState(State.NOT_READY);
    camera.switchDevice();
  };

  const capture = () => {
    console.log('capture');
    setState(State.CAPTURED);
    setCapturedAt(new Date());
    pauseDetection();
  };

  const closeCapture = () => {
    setState(State.LIVE);
    startDetection();
  }

  useEffect(() => {
    camera.start()
    return () => camera.stop()
  }, [camera])


  if (error) {
    return (
      <p>
        <Trans>photo_no_webcam</Trans>
      </p>
    );
  }

  const PhotoLoader = () => <Loader text={t('photo_lab_loading')} />;

  const mirrorClass = mirror ? styles.mirror : '';

  // console.log('render');

  return (
    <>
      {state === State.NOT_READY && <PhotoLoader />}
      <div className={styles.container} style={{ visibility: state === State.NOT_READY ? 'hidden' : 'visible' }}>
        <canvas ref={canvasRef} className={`${styles.canvas} ${mirrorClass}`} />

        {/* live controls */}
        {state === State.LIVE && (
          <>
            {canSwitch && <button onClick={switchDevice} className={styles.switchBtn} />}

            <div className={`${styles.controls} ${styles.packBtns}`}>
              <div className={`${styles.controlsContainer}`}>
                <button className={`${styles.camBtn} ${pack ? styles[`pack${pack}Btn`] : ''}`} onClick={capture}>
                  {pack && <img src={hats[pack - 1]} alt={''} />}
                </button>
                {hats.map((hat, hatIndex) => {
                  if (pack === hatIndex + 1) return null;
                  return (
                    <button key={hatIndex} onClick={() => setPack(hatIndex + 1)} className={styles[`pack${hatIndex + 1}Btn`]}>
                      <img src={hat} alt={''} />
                    </button>
                  );
                })}
              </div>
            </div>

            {isDev && (
              <button onClick={() => setShowCam(!showCam)} style={{ position: 'absolute', bottom: 10, left: 100 }}>
                cam
              </button>
            )}
          </>
        )}

        {state === State.CAPTURED && capturedAt && canvasRef.current && (
          <div className={`${styles.controls} ${styles.downloadBtn}`}>
            <Button
              callback={() => setState(State.SAVED)}
              href={canvasRef.current.toDataURL('image/jpeg', 1)}
              props={{ download: `Croisière-bateaux-parisiens-${formatDate(capturedAt)}.jpg` }}>
              {t('photo_save_preview')}
            </Button>
          </div>
        )}

        {state === State.SAVED && (
          <div className={`${styles.controls} ${styles.savedBtn}`}>
            <Button callback={() => {}}>{t('photo_saved')}</Button>
          </div>
        )}

        {[State.SAVED, State.CAPTURED].includes(state) && <CloseButton onClick={closeCapture} className={styles.closeBtn} />}

        <video
          id={'video'}
          ref={videoRef}
          playsInline
          muted
          className={`${styles.video} ${mirrorClass}`}
          style={{ display: showCam ? 'block' : 'none' }}
        />
      </div>
    </>
  );
};
