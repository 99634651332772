import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/global.scss';
import App from './app';
import * as Sentry from '@sentry/react';
// import reportWebVitals from './reportWebVitals';

const isDev = !!parseInt(process.env.REACT_APP_DEBUG || '');

const environment = isDev ? 'dev' : window.location.hostname === 'app.pp.bateauxparisiens.ppts.dev' ? 'preprod' : 'prod';

if (!isDev) {
  Sentry.init({
    dsn: 'https://c3a56e7180d6415b94129cb68e17a688@o4505046946742272.ingest.sentry.io/4505046949494784',
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: window.location.hostname === 'app.pp.bateauxparisiens.ppts.dev' ? 1 : 0.3,
    release: process.env.REACT_APP_ENV_GIT_HASH,
    environment,
  });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App key={'app'} />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// if(isDev) {
//   reportWebVitals(console.log.bind(console));
// }
