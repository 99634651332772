import { LangCode } from '../state/lang';

export const langs: Record<LangCode, [string, string]> = {
  [LangCode.FR]: ['FR', 'Français'],
  [LangCode.EN]: ['EN', 'English'],
  [LangCode.DE]: ['DE', 'Deutsch'],
  [LangCode.ES]: ['SP', 'Español'],
  [LangCode.PT]: ['PT', 'Português'],
  [LangCode.IT]: ['IT', 'Italiano'],
  [LangCode.ZH]: ['CHN', '中文'],
  [LangCode.HI]: ['HIN', 'हिन्दी'],
  [LangCode.RU]: ['RUS', 'русский язык'],
  [LangCode.JP]: ['JPN', '日本語'],
  [LangCode.AR]: ['AR', 'العربية'],
};
