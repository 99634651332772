type StreamListener = (stream: MediaStream) => void;

type StreamConstraints = MediaStreamConstraints & { video: { facingMode?: { exact: string }, deviceId?: string } };

const Modes = ['user', 'environment'];

class Camera {
  listeners: Array<StreamListener> = [];

  devices?: MediaDeviceInfo[];

  currentModeIndex = 0;

  currentDeviceIndex = 0;

  stream?: MediaStream;

  streamParams?: StreamConstraints;

  streamParamHash?: string;

  openPromise?: Promise<MediaStream>;

  supports?: MediaTrackSupportedConstraints;

  static instance?: Camera;

  static getInstance() {
    if (!Camera.instance) {
      Camera.instance = new Camera();
    }
    (window as any).camera = Camera.instance;
    return Camera.instance;
  }

  unsubscribe(listener: StreamListener) {
    this.listeners = this.listeners.filter((l) => l !== listener);
  }

  subscribe(listener: StreamListener) {
    this.listeners.push(listener);
  }

  protected async retrieveDevices() {
    const mediaDevices: MediaDeviceInfo[] = await navigator.mediaDevices.enumerateDevices();
    this.devices = mediaDevices.filter(({ kind }) => kind === 'videoinput');
    this.supports = navigator.mediaDevices.getSupportedConstraints();
    if (
      !this.supports.width ||
      !this.supports.height ||
      !this.supports.frameRate ||
      !this.supports.facingMode
    ) {
      throw new Error('Camera not supported')
    }
    console.log(this.supports);
  }

  async start() {
    if (this.devices === undefined) {
      await this.retrieveDevices();
    }
    if (!this.devices) {
      throw new Error('no camera detected');
    }
    if (this.stream) return;
    this.openStream({
      audio: false,
      video: {
        // width: 1280,
        // height: 720,
        // deviceId: this.devices[this.currentDeviceIndex].deviceId,
        facingMode: {
          exact: Modes[this.currentModeIndex],
        },
        frameRate: {
          ideal: 30,
        },
      },
    });
  }

  async openStream(constraints: StreamConstraints) {
    const contraintsHash = JSON.stringify(constraints);

    console.log(constraints, this.streamParams,contraintsHash !== this.streamParamHash, this.openPromise);

    if (contraintsHash !== this.streamParamHash) {
      if (
        this.streamParams?.video.facingMode?.exact !== constraints.video.facingMode?.exact ||
        this.streamParams?.video.deviceId !== constraints.video.deviceId
      ) {
        this.stop();
      }
      this.streamParamHash = contraintsHash;
      this.streamParams = constraints;
      this.openPromise = navigator.mediaDevices.getUserMedia(constraints);
      try {
        this.stream = await this.openPromise;
      } catch(reason:any) {
        console.error(`Error <code>${reason.name}</code> in constraint <code>${reason.constraint}</code>: ${reason.message}`)
        if(reason.constraint === 'facingMode') {
          delete constraints.video.facingMode;
          await this.openStream(constraints);
        }
      }
      this.retrieveDevices();
      this.listeners.forEach((listener) => this.stream && listener(this.stream));
      this.openPromise = undefined;
    } else {
      if(this.openPromise) {
        await this.openPromise;
        this.listeners.forEach((listener) => this.stream && listener(this.stream));
      } else {
        this.listeners.forEach((listener) => this.stream && listener(this.stream));
      }
    }
  }

  stop() {
    if (!this.stream) return;
    console.log('stop stream');

    this.stream.getTracks().forEach((track, i) => {
      console.log('stop', i);
      track.stop();
      track.enabled = false;
      if (this.stream) this.stream.removeTrack(track);
    });
    this.stream = undefined;
    this.streamParamHash = undefined;
  }

  isMirror() {
    return this.stream && this.stream.getVideoTracks()[0].getSettings().facingMode === 'user';
  }

  switchDevice() {
    if (!this.devices) return;
    const currentModeIndex = (this.currentModeIndex + 1) % Modes.length;
    const currentDeviceIndex = (this.currentDeviceIndex + 1) % this.devices.length;
    const params = JSON.parse(JSON.stringify(this.streamParams));
    if (!params) return;
    this.currentModeIndex = currentModeIndex;
    this.currentDeviceIndex = currentDeviceIndex;
    params.video.facingMode.exact = Modes[this.currentModeIndex];
    params.video.deviceId = this.devices[this.currentDeviceIndex]
    this.openStream(params);
  }
}

export default Camera;
