import { burgerOpenAtom, screenAtom, useGoBack, useGoToScreen } from '../../state/app';
import backArrow from '../../imgs/icons/back-arrow.svg';

import styles from './styles.module.scss';
import { useAtomValue, useSetAtom } from 'jotai';

function BackButton() {
  const setBurgerOpenState = useSetAtom(burgerOpenAtom);
  const screen = useAtomValue(screenAtom);
  const goToScreen = useGoToScreen();
  const goBack = useGoBack();

  const onClick = () => {
    setBurgerOpenState(false);
    switch (screen.name) {
      case 'quizz':
        return goBack();
      case 'monument':
        return goToScreen('monuments');
      default:
        goToScreen('home');
    }
  };

  return (
    <button className={styles.backButton} onClick={onClick}>
      <img src={backArrow} alt={'back arrow'} />
    </button>
  );
}

export default BackButton;
