import { useAtom } from 'jotai';

import { LangCode, langAtom, useTranslate } from '../state/lang';
import Button from '../component/Button';
import LangSelector from '../component/LangSelector';
import { langs } from '../constants/langs';

type WelcomeProps = {
  onReady: (lang: LangCode) => void;
};

export const Welcome = ({ onReady }: WelcomeProps) => {
  const [lang, setLang] = useAtom(langAtom);
  const t = useTranslate();

  return (
    <div style={{ paddingBottom: 30 }}>
      <LangSelector languages={langs} setLang={setLang} currentLang={lang} />
      <Button disabled={!lang} callback={() => onReady(lang)}>
        {t('validate')}
      </Button>
    </div>
  );
};
