function guidGenerator() {
  const S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  return `${S4()}${S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
}
const vid = guidGenerator();
export function sendPageEvent(event: string, params: Array<number | string>) {
  if (!process.env.REACT_APP_EVENT_BASE_URL) return;
  fetch(process.env.REACT_APP_EVENT_BASE_URL, {
    method: 'POST',
    mode: 'no-cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: { 'Content-Type': 'application/json' },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify([vid, event, ...params]),
  });
}
