import { useEffect, useState } from 'react';
import Loader from '../component/Loader';
import { useTranslate } from '../state/lang';

export const Loading = () => {
  const t = useTranslate();
  const [loop, setLoop] = useState(0);

  const loadingMessage = loop % 2 === 0 ? t('screen_loading_text') : t('screen_loading_text_2');

  useEffect(() => {
    const interval = setInterval(() => {
      setLoop((l) => l + 1);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return <Loader text={loadingMessage} />;
};
