/* eslint-disable @typescript-eslint/no-var-requires */
import { Pose } from '@tensorflow-models/pose-detection';
import hat1 from '../imgs/pack-ar/1-hat.png';
import mustache1 from '../imgs/pack-ar/1-mustache.png';
import sash1 from '../imgs/pack-ar/1-sash.png';
import hat2 from '../imgs/pack-ar/2-hat.png';
import sash2 from '../imgs/pack-ar/2-sash.png';
import { isDev } from '../state/app';

// test
// import hat3 from '../imgs/pack-ar/3-hat.png';
// import mustache3 from '../imgs/pack-ar/3-mustache.png';
// import scarf3 from '../imgs/pack-ar/3-scarf.png';

type Pack = {
  hat: HTMLImageElement;
  mustache: HTMLImageElement;
  scarf: HTMLImageElement;
  sash: HTMLImageElement;
};

const packs: Array<Partial<Pack>> = [];

const loadedImg = (imgPath: string): Promise<HTMLImageElement> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = imgPath;
    img.onload = () => resolve(img);
  });
};

(async () => {
  packs[1] = {
    hat: await loadedImg(hat1),
    mustache: await loadedImg(mustache1),
    sash: await loadedImg(sash1),
  };
  packs[2] = {
    hat: await loadedImg(hat2),
    sash: await loadedImg(sash2),
    // mustache: await loadedImg(mustache4)
  };
  // packs[3] = {
  //   hat: await loadedImg(hat3),
  //   mustache: await loadedImg(mustache3),
  //   scarf: await loadedImg(scarf3),
  // };
})();

const hatBaseWidth = 600;
const mustacheBaseWidth = 400;
const scarfBaseWidth = 1000;
const sashBaseWidth = 1000;

export const draw = (pose: Pose, ctx: CanvasRenderingContext2D, packId: number) => {
  if (packs[packId] === undefined) return;

  const pack = packs[packId];

  const keypoints = pose.keypoints;

  const eyeRight = keypoints.find((p) => p.name === 'right_eye');
  const eyeLeft = keypoints.find((p) => p.name === 'left_eye');
  const nose = keypoints.find((p) => p.name === 'nose');
  const leftShoulder = keypoints.find((p) => p.name === 'left_shoulder');
  const rightShoulder = keypoints.find((p) => p.name === 'right_shoulder');

  if (!eyeRight || !eyeLeft) return null;

  const faceAngle = Math.PI + Math.atan2(eyeRight.y - eyeLeft.y, eyeRight.x - eyeLeft.x);
  const eyeDistance = Math.sqrt(Math.pow(eyeLeft.x - eyeRight.x, 2) + Math.pow(eyeLeft.y - eyeRight.y, 2)) * 1.5;
  const eyesCenterX = eyeLeft.x + (eyeRight.x - eyeLeft.x) / 2;
  const eyesCenterY = eyeLeft.y + (eyeRight.y - eyeLeft.y) / 2;

  if (pack.hat) {
    const hatRatio = pack.hat.width / hatBaseWidth;
    const hatWidth = eyeDistance * hatRatio;
    const hatHeight = (hatWidth * pack.hat.height) / pack.hat.width;
    ctx.translate(eyesCenterX, eyesCenterY);
    ctx.rotate(faceAngle);
    ctx.translate(-hatWidth / 2, -hatHeight / 2);
    ctx.drawImage(pack.hat, 0, 0, hatWidth, hatHeight);
    ctx.translate(hatWidth / 2, hatHeight / 2);
    ctx.rotate(-faceAngle);
    ctx.translate(-eyesCenterX, -eyesCenterY);
  }

  if (pack.mustache && nose) {
    const mustacheRatio = pack.mustache.width / mustacheBaseWidth;
    const mustacheWidth = eyeDistance * mustacheRatio;
    ctx.translate(nose.x, nose.y);
    ctx.rotate(faceAngle);
    ctx.translate(-mustacheWidth / 2, -(mustacheWidth * pack.mustache.height) / (2 * pack.mustache.width));
    ctx.drawImage(pack.mustache, 0, 0, mustacheWidth, (mustacheWidth * pack.mustache.height) / pack.mustache.width);
    ctx.translate(mustacheWidth / 2, (mustacheWidth * pack.mustache.height) / (2 * pack.mustache.width));
    ctx.rotate(-faceAngle);
    ctx.translate(-nose.x, -nose.y);
  }

  if (leftShoulder && rightShoulder) {
    const shouldersDistance =
      Math.sqrt(Math.pow(leftShoulder.x - rightShoulder.x, 2) + Math.pow(leftShoulder.y - rightShoulder.y, 2)) * 1.5;
    const shouldersCenterX = leftShoulder.x + (rightShoulder.x - leftShoulder.x) / 2;
    const shouldersCenterY = leftShoulder.y + (rightShoulder.y - leftShoulder.y) / 2;
    const shoulderAngle = Math.PI + Math.atan2(rightShoulder.y - leftShoulder.y, rightShoulder.x - leftShoulder.x);

    if (pack.scarf) {
      const scarfRatio = pack.scarf.width / scarfBaseWidth;
      const scarfWidth = shouldersDistance * scarfRatio;
      ctx.translate(shouldersCenterX, shouldersCenterY);
      ctx.rotate(shoulderAngle);
      ctx.translate(-scarfWidth / 2, -(scarfWidth * pack.scarf.height) / pack.scarf.width);
      ctx.drawImage(pack.scarf, 0, 0, scarfWidth, (scarfWidth * pack.scarf.height) / pack.scarf.width);
      ctx.translate(scarfWidth / 2, (scarfWidth * pack.scarf.height) / pack.scarf.width);
      ctx.rotate(-shoulderAngle);
      ctx.translate(-shouldersCenterX, -shouldersCenterY);
    }

    if (pack.sash) {
      const sashRatio = pack.sash.width / sashBaseWidth;
      const sashWidth = shouldersDistance * sashRatio;
      const sashHeight = (sashWidth * pack.sash.height) / pack.sash.width;
      ctx.translate(shouldersCenterX, shouldersCenterY);
      ctx.rotate(shoulderAngle);
      ctx.translate(-sashWidth / 2, -sashHeight / 2);
      ctx.drawImage(pack.sash, 0, 0, sashWidth, sashHeight);
      ctx.translate(sashWidth / 2, sashHeight / 2);
      ctx.rotate(-shoulderAngle);
      ctx.translate(-shouldersCenterX, -shouldersCenterY);
    }
  }

  if (isDev) {
    // draw keypoins
    keypoints.forEach((keypoint) => {
      ctx.beginPath();
      ctx.fillStyle = 'green';
      ctx.arc(keypoint.x, keypoint.y, 5, 0, 2 * Math.PI);
      ctx.fill();
      ctx.stroke();
    });
  }
};
