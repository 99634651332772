import { useCallback, useRef, useState } from 'react';
import { useAtom } from 'jotai';

import { langs } from '../../constants/langs';
import { langAtom, LangCode, useTranslate } from '../../state/lang';
import downArrow from '../../imgs/icons/down-arrow.svg';

import styles from './styles.module.scss';

import { isInternetConnected } from '../../service/network';
import BaseModal from '../ModalNotification/base';

function LangDropdown() {
  const [isOpen, setIsOpen] = useState(false);
  const [lang, setLang] = useAtom(langAtom);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const t = useTranslate();

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const closeDropdown = useCallback(() => {
    if (dropdownRef.current) {
      dropdownRef.current.classList.add(styles.closing);
      dropdownRef.current.addEventListener('animationend', () => {
        setIsOpen(false);
      });
    }
  }, []);

  const handleOpenDropdown = useCallback(() => {
    if (isOpen) {
      closeDropdown();
    } else {
      setIsOpen(true);
    }
  }, [isOpen, closeDropdown]);

  const handleLangChange = useCallback(
    async (newLang: LangCode) => {
      closeDropdown();
      if (await isInternetConnected()) {
        setLang(newLang);
        console.log('Changing language to: ' + newLang);
      } else {
        setModalOpen(true);
        console.log('Cannot change language to "' + newLang + '". Please check your Internet connexion and try again.');
      }
    },
    [closeDropdown, setLang],
  );

  const dropdown = (
    <div className={styles.dropdown} ref={dropdownRef}>
      <div>
        <div className={`${styles.dropdownItem} ${styles.selected} ${lang === LangCode.AR ? styles.dropArabic : ''}`}>
          <span>{langs[lang][0]}</span>
          {langs[lang][1]}
        </div>
        {Object.keys(langs)
          .filter((l) => l !== lang)
          .map((code) => (
            <div
              key={code as string}
              className={`${styles.dropdownItem} ${code === LangCode.AR ? styles.dropArabic : ''}`}
              onClick={() => handleLangChange(code as LangCode)}>
              <span>{langs[code as LangCode][0]}</span>
              {langs[code as LangCode][1]}
            </div>
          ))}
      </div>
    </div>
  );

  return (
    <div>
      <div className={styles.parent}>
        <div className={styles.currentLang} onClick={handleOpenDropdown}>
          {langs[lang][0]} <img className={isOpen ? styles.open : ''} src={downArrow} alt={'arrows icons'} />
        </div>
        {isOpen && dropdown}
      </div>

      <BaseModal isOpen={modalOpen} handleClose={() => setModalOpen(false)}>
        <h4>{t('model_error_network')}</h4>
      </BaseModal>
    </div>
  );
}

export default LangDropdown;
