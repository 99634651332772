import { LangCode } from '../state/lang';
import data from '../i18n.json';
const trads = data as unknown as Trads;

type Trads = Record<LangCode, Record<string, string>>;

export const t = (code: LangCode, key: string) => {
  if (trads[code] && trads[code][key]) return trads[code][key];

  return `${code} - ${key}`;
};
