/* eslint-disable max-len */

import styles from './styles.module.scss';

type LoaderProps = {
  text?: string;
};

function Loader({ text }: LoaderProps) {
  return (
    <div className={styles.loader}>
      {text && <h2>{text}</h2>}

      <svg className={styles.flower} width={'604.285'} height={'600.965'} viewBox={'0 0 604.285 600.965'}>
        <defs>
          <clipPath id={'clip-path'}>
            <rect id={'Rectangle_23057'} data-name={'Rectangle 23057'} width={'604.285'} height={'600.965'} fill={'none'} />
          </clipPath>
        </defs>
        <g id={'Groupe_41593'} data-name={'Groupe 41593'} clipPath={'url(#clip-path)'}>
          <path
            className={'leaf'}
            id={'leaf_1'}
            data-name={'leaf_1'}
            d={
              'M249.98,202.24c15.841,10.851-90.211,77.868-183.37,16.74-84.564-55.5-75.919-138.425-43.431-176.7,48.957-57.666,93.873-4.447,150,88.433,39.084,64.626,67.96,65.5,76.8,71.529'
            }
            transform={'translate(0.001 112.607)'}
            fill={'#fff'}
          />
          <path
            className={'leaf'}
            id={'leaf_2'}
            data-name={'leaf_2'}
            d={
              'M106.018,271.949c-5.4,18.432-101.911-61.721-72.578-169.228C60.066,5.185,141.632-12.24,188.076,6.842c69.966,28.733,33.216,87.905-37.749,169.978-49.428,57.095-41.318,84.878-44.309,95.129'
            }
            transform={'translate(171.282 -0.004)'}
            fill={'#fff'}
          />
          <path
            className={'leaf'}
            id={'leaf_3'}
            data-name={'leaf_3'}
            d={
              'M49.371,144.418c-19.2.543,27.205-116.038,138.5-121.335,101.026-4.826,142.787,67.367,139,117.43-5.718,75.434-73.342,58.765-173.34,16.633-69.566-29.34-93.487-13.064-104.153-12.728'
            }
            transform={'translate(277.179 140.283)'}
            fill={'#fff'}
          />
          <path
            className={'leaf'}
            id={'leaf_4'}
            data-name={'leaf_4'}
            d={
              'M46.383,53.51c-6.446-18.118,118.744-9.966,158.2,94.258,35.8,94.558-19.938,156.586-68.738,168.443-73.506,17.9-78.568-51.577-69.38-159.7,6.4-75.263-16.462-92.966-20.081-103'
            }
            transform={'translate(283.257 281.954)'}
            fill={'#fff'}
          />
          <path
            className={'leaf'}
            id={'leaf_5'}
            data-name={'leaf_5'}
            d={
              'M240.8,50.282c15.212-11.729,46.187,109.878-40.754,179.586C121.179,293.13,44.96,259.293,18.6,216.554c-39.719-64.4,24.778-90.66,130.444-115.332,73.542-17.183,83.322-44.4,91.76-50.941'
            }
            transform={'translate(41.525 303.807)'}
            fill={'#fff'}
          />
        </g>
      </svg>
    </div>
  );
}

export default Loader;
